import React, { useState, useEffect, useImperativeHandle, useMemo, useCallback, ReactNode, useRef, forwardRef, useContext } from "react";
import classnames from 'classnames';
import './Partnerships.scss';
import { DashboardContent } from "@/components/dashboard";
import OutletWithProps from "@/components/OutletWithProps";
import { useTenantApi } from "@/hooks";
import { useSharedApi } from "@/hooks/useSharedApi";
import { PartnerDetails } from "@/types/ApiTypes";
import { ByzzerMask } from "@/components/ByzzerMask/ByzzerMask";
import { ByzzerCard } from "@/components/ByzzerCard";

export type PartnershipsProps = {
    data?: any;
}

const baseClassName = 'byz-partnerships';

const links = [];


export const Partnerships = ({
    ...props
}: PartnershipsProps) => {

    const { getPartnerships } = useSharedApi();

    const [partnerships, setPartnerships] = useState<PartnerDetails[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true)
        ;(async () => {
            try {
                const partners = await getPartnerships();
                const sortedPartners = [...partners].sort((a, b) => a.partnerOrder - b.partnerOrder);
                setPartnerships(sortedPartners);
            } catch (e) {
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        })()
    }, []);

    function handlePartnerCardClick(url?: PartnerDetails['partnerUrl']) {
        if (url) {
            window.open(url, '_blank');
        }
    }


    return (
        <DashboardContent title={'Our Partners'} className={baseClassName} links={links}>
            <ByzzerMask show={isLoading} loading={isLoading}/>
            <div className={classnames(`${baseClassName}__container`)}>
                <div className={classnames(`${baseClassName}__content`)}>
                    <div className={classnames(`${baseClassName}__cards`)}>
                        <div className={classnames(`${baseClassName}__thing`)}>
                            {partnerships.map(({id, logoUrl, name, type, partnerUrl}, index) => {
                                return (
                                    <ByzzerCard
                                        key={id}
                                        className={`${baseClassName}__partner`}
                                        thumbnailUrl={logoUrl!}
                                        title={name}
                                        subTitle={type}
                                        onClick={Boolean(partnerUrl) ? () => handlePartnerCardClick(partnerUrl) : undefined}
                                        centeredContent={true}
                                    />
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </DashboardContent>
    );

};

export default Partnerships;

Partnerships.displayName = 'Partnerships';