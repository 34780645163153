import {ByzzerTable} from '@/components/ByzzerTable';
import {DodFactFilterType, DodFilterType} from '@/components/DodConfigEditor/types';
import {useApp, useUser} from '@/contexts/UserContext';
import {
    ByzzerButton,
    ByzzerChangeEventHandler,
    ByzzerCheckableChangeEvent,
    ByzzerCheckbox,
    ByzzerTipIcon,
} from '@byzzer/ui-components';
import {ColDef, GridOptions} from 'ag-grid-community';
import {AgGridReact} from 'ag-grid-react';
import classNames from 'classnames';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import './DodFactValuePickerTable.scss';
import {DodFactSet, DodFilters} from '@/types/DodRun';
import {ByzzerLink} from '@/components/form';
import {cloneDeep, debounce, intersection} from "lodash";
import useStateRef from "react-usestateref";
import {TippyProps} from "@tippyjs/react";
import {useCategoryService} from "@/services/category.service";
import {alertSelectionLimitExceed, filterValuesToStrings} from '@/components/DodConfigEditor/common/utils'

export type DodFactValuePickerTableProps = {
    name?: string;
    className?: string;
    filterType: DodFilterType<DodFactFilterType>;
    actions?: ActionConfig[];
    includeActions?: boolean;
    filters: DodFilters;
    value: DodFactSet[];
    filterText?: string;
    onApply?: ByzzerChangeEventHandler<DodFactSet[]>;
    limit?: number;
};
const baseClassName = 'dod-fact-value-picker-table';

const defaultTippyOptions: TippyProps = {
    appendTo: () => {
        return document.body;
    }
}

const checkForTooltipCondition = (displayName) => {
    if (['% ACV (Max)', '$ ACV Selling (Max)'].includes(displayName)) return true;
    if (
        displayName.includes('% $ Incremental') ||
        displayName.includes('% Units Incremental') ||
        displayName.includes('% $ Lift') ||
        displayName.includes('% Unit Lift')
    )
        return true;
    return false;
};

const tooltipForFact = (fact) => {
    let customFactName = fact;
    if (fact === '% ACV (Max)') {
        customFactName = '% ACV';
    } else if (fact === '$ ACV Selling (Max)') {
        customFactName = '$ ACV Selling';
    } else if (fact.includes('% $ Incremental') || fact.includes('% Units Incremental')) {
        customFactName = 'Incremental';
    } else if (fact.includes('% $ Lift') || fact.includes('% Unit Lift')) {
        customFactName = 'Lift';
    }

    // const factSell = fact === '% ACV (Max)' ? '% ACV' : '$ ACV Selling';
    switch (customFactName) {
        case '% ACV':
            return `At the UPC and week level, ${fact} is the same fact as ${customFactName}. ${fact} differs from ${customFactName} at any hierarchy level that is higher than UPC, because it is the maximum ${customFactName} for the items and weeks combination based on the selections.`;
        case '$ ACV Selling':
            return `At the UPC level, ${fact} is the same fact as ${customFactName}. ${fact} differs from ${customFactName} at any hierarchy level that is higher than UPC, because it is the maximum ${customFactName} out of any item based on the selections.`;
        case 'Incremental':
            return `${fact} is the incremental volume compared to the observed volume. Use this metric to understand what portion of your total sales were incremental. Formula = (Incremental Volume/Observed Volume)*100`;
        case 'Lift':
            return `${fact} is the incremental volume compared to the base volume. Use this metric to understand the % of sales that were gained over the base, or expected, volume. Formula = (Incremental Volume/Base Volume)*100`;
        default:
            break;
    }
};
const factToolTipForTDPAndACV = (dataItem: DodFactSet) => {
    // todo: checkCategoryForDepartment to be added
    if ((dataItem.display.toLocaleLowerCase().includes('tdp') || dataItem.display.toLocaleLowerCase().includes('acv'))) {
        return (
            <ByzzerTipIcon
                interactive
                tipLocation={'top'}
                tippyProps={defaultTippyOptions}
                tip={<div>
                    Distribution facts are calculated using Category Weighted Distribution for all Alcohol
                    products. Read our{' '}
                    <ByzzerLink theme={'dark'} target="_blank" rel="noreferrer"
                                href={'https://learn.byzzer.ai/en/articles/6289614-understanding-distribution-facts'}>
                        ACV Article
                    </ByzzerLink>{' '}
                    to learn more about what this means.
                </div>}
            />
        );
    }
    return null;
};

const checkboxColumnWidth = 65;
const flagToFactMapping: Record<string, string> = {
    cp: 'fact',
    ya: 'yearAgo',
    cvya: 'changeVsYearAgo',
    pcvya: 'percentChangeVsYearAgo',
    c: 'category',
    cya: 'categoryYearAgo',
    b: 'brand',
    bya: 'brandYearAgo',
    ps: 'productSelections',
    psya: 'productSelectionsYearAgo',
}

const flagToAllowedIndexMapping: Record<string, number> = {
    cp: 1,
    ya: 2,
    cvya: 4,
    pcvya: 11,
    c: 5,
    cya: 6,
    b: 7,
    bya: 8,
    ps: 9,
    psya: 10,
}

const coreFlags: string[] = [
    'cp',
    'ya',
    'cvya',
    'pcvya',
];
const shareFlags: string[] = [
    'c',
    'cya',
    'b',
    'bya',
    'ps',
    'psya'
];

const checkboxGridOptions: GridOptions = {
    rowHeight: 24
}
const defaultColDef = {
    wrapText: true,
    autoHeight: true,
};

type FlagAndIndex = {
    flag: string;
    index: number;
}

const DodFactValuePickerTable = ({
                                     filterType,
                                     actions,
                                     includeActions,
                                     className,
                                     name,
                                     onApply,
                                     filters,
                                     filterText = '',
                                     value,
                                     limit = Infinity,
                                     ...props
                                 }: DodFactValuePickerTableProps) => {
    const gridRef = useRef<AgGridReact>(null);
    const {factConfig, categories: companyCategories} = useApp();
    const {getCategoriesForDepartments, getAllCategoriesByDepartmentsAndSuperCategories} = useCategoryService();
    const [selections, setSelections] = useState<Record<string, boolean>>({})
    const [currentFacts, setCurrentFacts, currentFactsRef] = useStateRef<any[]>([]);
    const [matchingFacts, setMatchingFacts, matchingFactsRef] = useStateRef<DodFactSet[]>([]);
    const lastFlagAndIndex = useRef<FlagAndIndex | null>(null);
    const shiftActive = useRef<boolean>(false);
    const isUPCSelected = Boolean(filters?.upcs.values.length  || filters?.upcs.summedSelections.length);
    const isBrandSelected = Boolean(filters?.brands.values.length || filters?.brands.summedSelections.length);
    const isFactDisabled = (dataItem: DodFactSet) => {
        return dataItem?.isUPCrequired && !isUPCSelected;
    };
    const { categories: userCategories } = useUser();

    const includesAlcoholCategories = useMemo<boolean>(() => {

        const alcoholCategories = getCategoriesForDepartments(['ALCOHOL']);
        let categories = filters.categories.values as string[];

        if (
            filters.categories.values === 'all' ||
            filters.categories.summedSelections.some((ss) => ss.values === 'all')
        ) {
            categories = getAllCategoriesByDepartmentsAndSuperCategories(filters.departments, filters.superCategories);
        } else {
            categories = filterValuesToStrings(filters.categories, userCategories);
        }
        return Boolean(intersection(alcoholCategories, categories).length);
    }, [filters]);

    const coreFactsColumnDefs: ColDef[] = useMemo(() => [{
        headerName: 'Fact Name',
        field: 'display',
        minWidth: 250,
        flex: 1,
        pinned: 'left',
        lockPinned: true,
        sortable: true,
        filter: false,
        headerClass: `${baseClassName}__fact-name-header`,
        cellClass: `${baseClassName}__fact-name-cell`,
        cellRenderer: ({data}) => (

            <div className={classNames(`${baseClassName}__fact-name`, {
                [`${baseClassName}__fact-name--disabled`]: isFactDisabled(data),
            })}>
                <h1>{data?.display}</h1>
                {includesAlcoholCategories && factToolTipForTDPAndACV(data)}
            </div>
        ),
        tooltipValueGetter: ({data}) => {
            return checkForTooltipCondition(data?.display) ? tooltipForFact(data?.display) : `${data?.display}`;
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Current Period',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'cp'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(1)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange}
                    onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'ya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(2)) return null;

            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Change vs Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'cvya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(4)) return null;

            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: '% Change vs Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'pcvya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(11)) return null;

            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }], []);
    const shareFactsColumnDefs: ColDef[] = useMemo<ColDef[]>(() => [{
        headerName: 'Fact Name',
        field: 'display',
        minWidth: 250,
        flex: 1,
        pinned: 'left',
        sortable: true,
        lockPinned: true,
        filter: true,
        headerClass: `${baseClassName}__fact-name-header`,
        cellClass: `${baseClassName}__fact-name-cell`,
        cellRendererParams: {
            flag: ''
        },
        cellRenderer: ({data}) => (
            <div className={classNames(`${baseClassName}__fact-name`, {
                [`${baseClassName}__fact-name--disabled`]: isFactDisabled(data),
            })}>
                <h1>{data?.display}</h1>
                {includesAlcoholCategories && factToolTipForTDPAndACV(data)}
            </div>
        ),
        tooltipValueGetter: ({data}) => {
            return checkForTooltipCondition(data?.display) ? tooltipForFact(data?.display) : `${data?.display}`;
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Category',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'c'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(5)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Category Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'cya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(6)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Brand',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'b'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(7)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={!isUPCSelected && !isBrandSelected}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Brand Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'bya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(8)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={!isUPCSelected && !isBrandSelected}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Product Selections',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'ps'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(9)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }, {
        minWidth: checkboxColumnWidth,
        flex: 1,
        headerClass: `${baseClassName}__checkbox-header`,
        headerName: 'Product Selections Year-Ago',
        cellClass: `${baseClassName}__checkbox-cell`,
        cellRendererParams: {
            flag: 'psya'
        },
        cellRenderer: ({data, flag, rowIndex, context}) => {
            const value = `${data.id}:${flag}`;
            if (!data.allowedFacts?.includes(10)) return null;
            return (
                <ByzzerCheckbox
                    onChange={handleFactChange} onClick={(e) => handleCheckboxClick(e, rowIndex, flag)}
                    value={value}
                    checked={context[value]}
                    disabled={isFactDisabled(data)}
                />
            );
        },
    }], []);

    useEffect(() => {
        setSelections(() => {

            refreshGrid();

            return value.reduce((selections, factSet) => {

                const {
                    id,
                    fact: currentPeriod,
                    yearAgo,
                    changeVsYearAgo,
                    percentChangeVsYearAgo,
                    category,
                    categoryYearAgo,
                    brand,
                    brandYearAgo,
                    productSelections,
                    productSelectionsYearAgo
                } = factSet;
                selections[`${id}:cp`] = Boolean(currentPeriod);
                selections[`${id}:ya`] = Boolean(yearAgo);
                selections[`${id}:cvya`] = Boolean(changeVsYearAgo);
                selections[`${id}:pcvya`] = Boolean(percentChangeVsYearAgo);
                selections[`${id}:c`] = Boolean(category);
                selections[`${id}:cya`] = Boolean(categoryYearAgo);
                selections[`${id}:b`] = Boolean(brand);
                selections[`${id}:bya`] = Boolean(brandYearAgo);
                selections[`${id}:ps`] = Boolean(productSelections);
                selections[`${id}:psya`] = Boolean(productSelectionsYearAgo);
                return selections;
            }, {} as Record<string, boolean>)
        })
    }, [value]);

    useEffect(() => {
        const facts = factConfig?.[filterType.data.type]
            ?.find((val) => val.value === filterType.type)
            ?.factLists ?? [];
        setCurrentFacts(facts);
        setMatchingFacts(facts);
    }, [filterType, factConfig]);

    useEffect(() => {
        filterFacts(filterText);
    }, [filterText]);

    const filterFacts = useCallback(debounce(async (filterText: string) => {
        if (filterText) {
            const matcher = new RegExp(filterText?.trim(), 'i');
            setMatchingFacts(currentFactsRef.current.filter(({display}) => matcher.test(display)));
        } else {
            setMatchingFacts(currentFactsRef.current);
        }
    }, 500), []);

    function handleCheckboxClick(e: React.MouseEvent<HTMLElement>, index: number, flag: string): void {
        // This function is responsible for handle shift select
        if (!(e.target as HTMLElement).matches('input[type="checkbox"]')) return;
        if(limit != Infinity) {
            // In this case the shift select functionality will be disabled. hence do nothing
            return;
        }

        shiftActive.current = Boolean(e.shiftKey && (!lastFlagAndIndex.current || lastFlagAndIndex.current?.flag === flag));

        if (e.shiftKey && lastFlagAndIndex.current?.flag === flag) {
            // todo: allow selection across columns
            const allowFactIndex = flagToAllowedIndexMapping[flag];
            const {index: lastIndex, flag: lastFlag} = lastFlagAndIndex.current;
            const start = lastIndex > index ? index : lastIndex;
            const end = lastIndex < index ? index : lastIndex;
            const newSelections = matchingFactsRef.current.slice(start, end + 1)
                .reduce((selections, {id, isUPCrequired, allowedFacts}) => ({
                    ...selections,
                    // don't select values that arent' visit becaause they're not allow or that are disabled
                    [`${id}:${flag}`]: allowedFacts.includes(allowFactIndex) && (!isUPCrequired || (isUPCrequired && isUPCSelected))
                }), {});
            setSelections(selections => {

                refreshGrid();

                return {
                    ...selections,
                    ...newSelections
                }
            });
        } else {
            lastFlagAndIndex.current = {
                index,
                flag
            };
        }
    }

    function handleFactChange({value, checked}: ByzzerCheckableChangeEvent<string>) {
        if (shiftActive.current) {
            // this means shift select
            // which will be handled by handleCheckboxClick hence do nothing
            shiftActive.current = false;
            return;
        }
        setSelections(selections => {
            const isSelectionLimitExceed = Object.keys(selections).filter((fact) => selections[fact] === true).length >= limit
            if (checked && isSelectionLimitExceed) {
                alertSelectionLimitExceed('facts',limit)
            }
            return ({
                ...selections,
                ...(!isSelectionLimitExceed || !checked) && { [value]: checked }
            })
        });
        refreshGrid();
    }

    function handleClearClick() {
        // only remove the
        const flags = filterType.data.type === 'core' ? coreFlags : shareFlags;
        const factIds: number[] = currentFactsRef.current.map(fact => fact.id);

        setSelections(selections => {
            for (let flag of flags) {
                for (let id of factIds) {
                    delete selections[`${id}:${flag}`]
                }
            }

            return {...selections}
        });
        refreshGrid();
    }

    function refreshGrid() {
        setTimeout(() => {
            gridRef.current?.api.redrawRows();
        }, 10);
    }

    function handleApplyClick() {

        // the first entry in core facts contains every possible fact, all other entries are just subsets
        const allFacts = factConfig.core[0].factLists;
        const facts = Object.entries(selections).filter(([, value]) => value).reduce<DodFactSet[]>((facts, [value]) => {

            if (!value) return facts;

            const [id, type] = value.split(':');

            // attempt to find the fact in the current selections
            let fact = facts.find(fact => fact.id === Number(id));
            if (!fact) {
                // if it doesn't exist create it by cloning the value from the filters
                fact = cloneDeep(allFacts.find(fact => fact.id === Number(id)));
                facts.push(fact!);
            }

            fact![flagToFactMapping[type]] = true;

            return facts;
        }, [])

        onApply?.({
            name,
            value: facts,
        });
    }

    return (
        <div className={classNames(baseClassName, className)} {...props}>
            <div className={`${baseClassName}__options`}>
                <ByzzerTable ref={gridRef}
                             gridOptions={checkboxGridOptions}
                             context={selections}
                             key={`${filterType?.type}`}
                             rowData={matchingFacts}
                             suppressRowHoverHighlight
                             suppressCellFocus
                             suppressMovableColumns
                             columnDefs={filterType.data.type === 'core' ? coreFactsColumnDefs : shareFactsColumnDefs}
                             defaultColDef={defaultColDef}/>
            </div>

            <div className={`${baseClassName}__actions`}>
                {actions?.map((config, index) => (
                    <ByzzerButton
                        key={index}
                        type={config.type}
                        className={config.className}
                        iconType={config.icon}
                        tip={config.tip}
                        tipDelay={[500, 0]}
                        label={config.label}
                        onClick={() => config.action()}
                    />
                ))}
                <div className={`${baseClassName}__actions-divider`}/>
                <ByzzerButton type={'negative'} onClick={handleClearClick}>
                    Clear
                </ByzzerButton>
                <ByzzerButton onClick={handleApplyClick}>Apply</ByzzerButton>
            </div>
        </div>
    );
};

export default DodFactValuePickerTable;
