import React, { useState, useRef,useEffect, useContext} from 'react';
import next from '@/images/icons/storyIcons/next.svg';
import prevDisabled from '@/images/icons/storyIcons/prevDisabled.svg';
import nextDisabled from '@/images/icons/storyIcons/nextDisabled.svg';
import prev from '@/images/icons/storyIcons/prev.svg';
import zoomIn from '@/images/icons/storyIcons/zoom-in.svg';
import zoomOut from '@/images/icons/storyIcons/zoom-out.svg';
import downloadStoryPreview from '@/images/icons/storyIcons/downloadIconWhite.svg';
import warningIcon from '@/images/icons/warningIcon.svg';
import { getDefaultImageSrc } from '@/utils/utils';
import { ByzzerModal } from '@/components/form';
import { useStoriesContext } from '@/views/story/buildStory/StoriesContext';

const ImageSlider = ({ objectiveList, objectiveIndex, objectiveIndexChange, selectorName, validationErrorMessage }) => {
    const [storySliderData, setstorySliderData] = useState(objectiveList.length ? objectiveList : []);
    const sliderDatalength = storySliderData.length;
    const [zoomedView, setZoomedView] = useState(false);
    const objectiveRef = useRef();
    const currentIndexRef = useRef();
    const currentIndex = objectiveIndex;
    const [zoomOutIndex, setZoomOutIndex] = useState(currentIndex);
    const [zoomInIndex, setZoomInIndex] = useState(currentIndex);
    const [zoomOutPrevFlag, setZoomOutPrevFlag] = useState(false);
    const [zoomOutNextFlag, setZoomOutNextFlag] = useState(true);
    const [zoomInPrevFlag, setZoomInPrevFlag] = useState(false);
    const [zoomInNextFlag, setZoomInNextFlag] = useState(true);
    const [showTooltip, setShowTooltip] = useState(false);
    const { dataSpecificationValues,dataSpecificationValues:{sku} } = useStoriesContext();
    const imageBucketURL = import.meta.env.REACT_APP_IMAGES_BASE_URL;
    const storyGuideDownloadPath = `${imageBucketURL}/stories/guides/${sku}-story-guide.pdf`;

    useEffect(() => {
    setstorySliderData(objectiveList);
    }, [objectiveList])
    
    const nextSlide = () => {
        let index = zoomedView ? zoomInIndex : zoomOutIndex;
        if (!(index === sliderDatalength - 1)) {
            index += 1;
            zoomedView ? setZoomInIndex(index) : setZoomOutIndex(index);
        }
        if (index === sliderDatalength - 1) {
            zoomedView ? setZoomInNextFlag(false) : setZoomOutNextFlag(false);
        }
        if (index === 1) {
            zoomedView ? setZoomInPrevFlag(true) : setZoomOutPrevFlag(true);
        }
        if (!zoomedView && objectiveRef.current?.objectiveName !== storySliderData[index].objectiveName) {
            objectiveIndexChange(storySliderData[index].objectiveIndex, storySliderData[index].previewImageIndex);
            objectiveRef.current = storySliderData[index];
        }
    };
    const prevSlide = () => {
        let index = zoomedView ? zoomInIndex : zoomOutIndex;
        if (!(index === 0)) {
            index -= 1;
            zoomedView ? setZoomInIndex(index) : setZoomOutIndex(index);
        }
        if (index === 0) {
            zoomedView ? setZoomInPrevFlag(false) : setZoomOutPrevFlag(false);
        }
        if (index === sliderDatalength - 2) {
            zoomedView ? setZoomInNextFlag(true) : setZoomOutNextFlag(true);
        }
        if (!zoomedView && objectiveRef.current?.objectiveName !== storySliderData[index].objectiveName) {
            objectiveIndexChange(storySliderData[index].objectiveIndex, storySliderData[index].previewImageIndex);
            objectiveRef.current = storySliderData[index];
        }
    };
    const onClose = () => {
        setZoomedView(false);
        setZoomOutIndex(zoomInIndex);
        setZoomOutNextFlag(zoomInNextFlag);
        setZoomOutPrevFlag(zoomInPrevFlag);
        if (objectiveRef.current?.objectiveName !== storySliderData[zoomInIndex].objectiveName) {
            objectiveIndexChange(
                storySliderData[zoomInIndex].objectiveIndex,
                storySliderData[zoomInIndex].previewImageIndex
            );
            objectiveRef.current = storySliderData[zoomInIndex];
        }
    };
    const getZoomedCarousel = () => {
        setZoomedView(true);
        setZoomInIndex(zoomOutIndex);
        setZoomInNextFlag(zoomOutNextFlag);
        setZoomInPrevFlag(zoomOutPrevFlag);
    };
    
    //BYZ-10199, ability to download story-preview
    const getDownload = () =>{        
        if(storyGuideDownloadPath){
            window.open(storyGuideDownloadPath, '_blank');
        }        
    }
    const getImageCarousel = (current) => {
        return (
            <div className="story-preview-image-container">
                {storySliderData.map((slide, index) => {
                    return (
                        <div className={index === current ? 'slide active' : 'slide'} key={index}>
                            {index === current && (
                                <img
                                    src={`${imageBucketURL}${slide.previewImg}`}
                                    onError={(event) => getDefaultImageSrc(event)}
                                    className="story-preview-image"
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };
    const getImageHeaderSection = (current) => {
        return (
            <>
                <header className="story-preview-sub-heading">
                    <span>Objective: {storySliderData[current].objectiveName}</span>
                </header>
            </>
        );
    };
    const getImageInfoSection = (current) => {
        return (
            <>
                <div className="story-slider-pagination">
                    <span>
                        {' '}
                        Page {current + 1} of {sliderDatalength}{' '}
                    </span>
                </div>
                {selectorName !== 'review_and_run' && (
                    <>
                        <div>
                            <header className="story-preview-page-info-header">
                                <span>Page header</span>
                            </header>
                            <div className="story-preview-page-info">
                                <div className="story-preview-info-container">
                                    <div>{`${
                                        storySliderData[current]?.objectiveHeader === undefined
                                            ? 'Coming Soon'
                                            : storySliderData[current].objectiveHeader
                                    }`}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <header className="story-preview-page-info-header">
                                <span>About</span>
                            </header>
                            <div className="story-preview-page-about">
                                <div className="story-preview-info-container">
                                    <div>{`${
                                        storySliderData[current]?.objectiveAbout === undefined
                                            ? 'Coming Soon'
                                            : storySliderData[current].objectiveAbout
                                    }`}</div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </>
        );
    };
    const getZoomOutPreview = () => {
        if (currentIndexRef.current !== currentIndex) {
            currentIndexRef.current = currentIndex;
            setZoomOutIndex(currentIndex);
            currentIndex === 0 ? setZoomOutPrevFlag(false) : setZoomOutPrevFlag(true);
            currentIndex === sliderDatalength - 1 ? setZoomOutNextFlag(false) : setZoomOutNextFlag(true);
        }
        if (objectiveRef.current?.objectiveName !== storySliderData[currentIndex].objectiveName) {
            objectiveIndexChange(
                storySliderData[currentIndex].objectiveIndex,
                storySliderData[currentIndex].previewImageIndex
            );
            objectiveRef.current = storySliderData[currentIndex];
        }
        return (
            <div className="run-story__summary_container__preview__content">
                <div className={`run-story__summary_container__preview__content__header`}>
                    <span className="story-preview-heading">Your Story Preview</span>

                    {validationErrorMessage?.type === 'warning' && (
                        <>
                            <img
                                src={warningIcon}
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                            ></img>
                            {showTooltip && validationErrorMessage?.content && (
                                <>
                                    {' '}
                                    {Array.isArray(validationErrorMessage.content) ? (
                                        <p
                                            className={`run-story__summary_container__preview__content__header__tooltip`}
                                        >
                                            {validationErrorMessage.content.map((msg) => {
                                                return <p>{msg}</p>;
                                            })}
                                        </p>
                                    ) : (
                                        <p
                                            className={`run-story__summary_container__preview__content__header__tooltip`}
                                        >
                                            {validationErrorMessage.content}
                                        </p>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
                <div className={`run-story__summary_container__preview__content__slider`}>
                    {getImageHeaderSection(zoomOutIndex)}
                    <section className="story-slider">
                        {zoomOutPrevFlag && <img src={prev} className="story-arrow" onClick={() => prevSlide()} />}
                        {!zoomOutPrevFlag && <img src={prevDisabled} className="story-arrow-disabled" />}

                        {getImageCarousel(zoomOutIndex)}                        
                            <div className="story-preview-zoom-button" >
                                <img src={zoomIn} alt="zoom" onClick={() => getZoomedCarousel()} />
                                <img src={downloadStoryPreview} alt="download" onClick={getDownload}/>
                            </div>
                           
                        
                        {zoomOutNextFlag && <img src={next} className="story-arrow" onClick={() => nextSlide()} />}
                        {!zoomOutNextFlag && <img src={nextDisabled} className="story-arrow-disabled" />}
                    </section>
                    {getImageInfoSection(zoomOutIndex)}
                </div>
            </div>
        );
    };
    const getZoomInPreview = () => {
        return (
            <>
                {getImageHeaderSection(zoomInIndex)}
                <section className="story-slider">
                    {zoomInPrevFlag && <img src={prev} className="story-arrow" onClick={() => prevSlide()} />}
                    {!zoomInPrevFlag && <img src={prevDisabled} className="story-arrow-disabled" />}

                    {getImageCarousel(zoomInIndex)}

                    <div className="story-preview-zoom-button" onClick={() => onClose()}>
                        <img src={zoomOut} alt="zoom" />
                    </div>
                    {zoomInNextFlag && <img src={next} className="story-arrow" onClick={() => nextSlide()} />}
                    {!zoomInNextFlag && <img src={nextDisabled} className="story-arrow-disabled" />}
                </section>
                {getImageInfoSection(zoomInIndex)}
            </>
        );
    };
    return (
        <>
            {zoomedView && (
                <ByzzerModal
                    show={zoomedView}
                    onClose={() => onClose()}
                    size={'large'}
                    type={'info'}
                    headerType={'none'}
                >
                    <div className="story-zoomed-preview"> {getZoomInPreview()} </div>
                </ByzzerModal>
            )}
            {getZoomOutPreview()}
        </>
    );
};
export default ImageSlider;
